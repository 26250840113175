import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";
import momentTimezone from "moment-timezone";

import { APIStatus } from "./APIStatus";

const initialState = {
  meteringDashboardStatus: APIStatus.idle,
  meterList: null,
  meterListError: null,
  meterInfoTableList: null,
  meterInfoTableListError: null,
  meterDetailsList: null,
  meterDetailsListError: null,
};

export const getEnergyFeed = createAsyncThunk(
  "getEnergyFeed",
  async (payload) => {
    const response = await axios.get(
      `/${payload.organization}/feeds/Energy/data-cumulative/${payload.startDate}/${payload.endDate}`
    );
    return response;
  }
);

export const getFeedsCumulative = createAsyncThunk(
  "getFeedsCumulative",
  async (payload) => {
    let apiEndPoint;
    let isDayExtended = false;
    let startFromDate = moment(`${payload.startDate} 00:00:00`)
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");
    let endToDate = moment(`${payload.endDate} 23:59:59`)
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");

    if (payload.wise === "month" || payload.wise === "week") {
      apiEndPoint = `/${payload.organization}/feeds-cumulative/${payload.feedKey}/${payload.startDate}/${payload.endDate}/${payload.wise}`;
    } else {
      const frequency = payload.frequency;

      if (frequency === 1440) {
        const startDate = moment(startFromDate);
        const endDate = moment(endToDate);

        const differenceInDays = endDate.diff(startDate, "days");

        if (differenceInDays === 0) {
          startFromDate = startDate
            .subtract(1, "days")
            .format("YYYY-MM-DD HH:mm:ss");
          isDayExtended = true;
        }
      }
      apiEndPoint = `/${payload.organization}/feeds/${payload.feedKey}/data-cumulative/${frequency}?completed_at__range=${startFromDate},${endToDate}`;
    }
    const response = await axios.get(apiEndPoint);
    let data = response.data;
    if (isDayExtended) {
      const startDate = moment(startFromDate).add(1, "days");
      const endDate = moment(endToDate);

      const filteredArray = data.filter((item) => {
        const date = moment(item[0]);
        return date.isBetween(startDate, endDate, undefined, "[]");
      });

      data = filteredArray;
    }
    return data;
  }
);

const meteringDashboardSlice = createSlice({
  name: "meteringDashboardSlice",
  initialState,
  reducers: {
    reset(state, action) {
      state = { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEnergyFeed.pending, (state, action) => {
        state.meteringDashboardStatus = APIStatus.loading;
      })
      .addCase(getEnergyFeed.fulfilled, (state, action) => {
        state.meteringDashboardStatus = APIStatus.loaded;
        state.meterList = action.payload ? action.payload.data : null;
      })
      .addCase(getEnergyFeed.rejected, (state, action) => {
        state.meteringDashboardStatus = APIStatus.failed;
        state.meterListError = action.error;
      })
      .addCase(getFeedsCumulative.pending, (state, action) => {
        state.meteringDashboardStatus = APIStatus.loading;
      })
      .addCase(getFeedsCumulative.fulfilled, (state, action) => {
        state.meteringDashboardStatus = APIStatus.loaded;
        state.meterDetailsList = action.payload || null;
      })
      .addCase(getFeedsCumulative.rejected, (state, action) => {
        state.meteringDashboardStatus = APIStatus.failed;
        state.meterDetailsListError = action.error;
      });
  },
});

export const { reset } = meteringDashboardSlice.actions;

export default meteringDashboardSlice.reducer;
