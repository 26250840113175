import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { APIStatus } from "./APIStatus";

const initialState = {
  assets: null,
  assetDataDetails: {
    count: null,
  },
  assetData: null,
  assetAttachHistory: null,
  isLoading: APIStatus.loading,
  getAssetLoading: APIStatus.loading,
  assetTypeBB: {
    accessShaftNodes: null,
    shaftOutline: null,
    trunkMh: null,
    trunkMhOutline: null,
    workSiteAssets: null,
  },
  assetTypeBBError: null,
  categoryBBError: null,
  categoryBB: {
    pipelineSetbackDtss: null,
    pipelineSetbackTrunkSewers: null,
    pipelineSetbackWaterPipes: null,
    pipelineDtss: null,
    pipelineTrunkSewers: null,
    pipelineWaterPipes: null,
    loading: null,
  },
  bbLocations: null,
  bbLocationsError: null,
  assetAlarms: null,
  assetAlarmsError: null,
  newAsset: null,
  newAssetError: null,
  noDeviceAsset: null,
  noDeviceAssetStatus: null,
  editedAsset: null,
  editedAssetError: null,
  attachedAsset: null,
  attachedAssetError: null,
  detachedAsset: null,
  detachedAssetError: null,
  searchAssetList: null,
  assetAttachmentHistoryList: null,
};

export const getAssetAttachmentHistoryList = createAsyncThunk(
  "getAssetAttachmentHistoryList",
  async (payload) => {
    let response = await axios.get(payload.url);
    return response;
  }
);

export const getAssetTypeBB = createAsyncThunk(
  "getAssetTypeBB",
  async (payload) => {
    let response = await axios.get(payload.url);
    return response;
  }
);

export const getCategoryLayerBB = createAsyncThunk(
  "getCategoryLayerBB",
  async (payload) => {
    let response = await axios.get(payload.url);
    response.fromPage = payload.fromPage;
    return response;
  }
);

export const getAllAssets = createAsyncThunk(
  "getAllAssets",
  async (payload) => {
    const response = await axios.get(payload.url);
    return response.data;
  }
);

export const getAssetsM = createAsyncThunk("assets", async (payload) => {
  const response = await axios.get(payload.url);
  const { results, count } = response.data;

  return { data: { results } };
});

export const addNewAsset = createAsyncThunk(
  "addNewAsset",
  (payload, { rejectWithValue }) => {
    return axios
      .post(payload.url, payload.data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return rejectWithValue(
          err?.response?.data?.detail || "Something went wrong"
        );
      });
  }
);

export const editAsset = createAsyncThunk(
  "editAsset",
  (payload, { rejectWithValue }) => {
    return axios
      .patch(payload.url, payload.data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return rejectWithValue(
          err?.response?.data?.detail || "Something went wrong"
        );
      });
  }
);

export const attachAsset = createAsyncThunk(
  "attachAsset",
  (payload, { rejectWithValue }) => {
    return axios
      .post(payload.url, payload.data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return rejectWithValue(
          err?.response?.data?.detail || "Something went wrong"
        );
      });
  }
);

export const replaceAssetDevice = createAsyncThunk(
  "replaceAssetDevice",
  (payload, { rejectWithValue }) => {
    return axios
      .post(payload.url, payload.data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return rejectWithValue(
          err?.response?.data?.detail || "Something went wrong"
        );
      });
  }
);

export const detachAsset = createAsyncThunk(
  "detachAsset",
  async (payload, { rejectWithValue }) => {
    return axios
      .patch(payload.url)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return rejectWithValue(
          err?.response?.data?.detail || "Something went wrong"
        );
      });
  }
);

export const getBBLocations = createAsyncThunk(
  "getBBLocations",
  async (payload) => {
    const response = await axios.get(payload.url);

    return response;
  }
);

export const getAssetAlarms = createAsyncThunk(
  "getAssetAlarms",
  async (payload) => {
    const response = await axios.get(payload.url);
    return response;
  }
);

const assetSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {
    resetAssetTypeBB(state, action) {
      state.assetAttachHistory = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAssetTypeBB.pending, (state, action) => {
      state.isLoading = APIStatus.loading;
    });
    builder.addCase(getAssetTypeBB.fulfilled, (state, action) => {
      state.isLoading = APIStatus.loaded;
      let value = action.payload.config.url;

      if (value !== null) {
        let trimdat = value.split("asset_type=");
        let assetType = trimdat.pop();
        switch (assetType) {
          case "geofence_pub_access_shaft_nodes":
            state.assetTypeBB.accessShaftNodes = action.payload.data;
            break;
          case "geofence_pub_shaft_outlines":
            state.assetTypeBB.shaftOutline = action.payload.data;
            break;
          case "geofence_pub_trunk_mh":
            state.assetTypeBB.trunkMh = action.payload.data;
            break;
          case "geofence_pub_trunk_mh_outlines":
            state.assetTypeBB.trunkMhOutline = action.payload.data;
            break;
          case "work_site":
            state.assetTypeBB.workSiteAssets = action.payload.data;
            break;
          default:
            console.log("Invalid Selection->", assetType);
        }
      }
    });
    builder.addCase(getAssetTypeBB.rejected, (state, action) => {
      state.isLoading = APIStatus.failed;
      state.assetTypeBBError = action.payload;
    });
    builder.addCase(getCategoryLayerBB.pending, (state, action) => {
      if (action.meta.arg.fromPage === "dashboard") {
        state.categoryBB.loading = APIStatus.loading;
      }
    });
    builder.addCase(getCategoryLayerBB.fulfilled, (state, action) => {
      let value = action.payload.config.url;
      if (value !== null) {
        let trimdat = value.split("&location_type=");
        let categoryName = trimdat.pop();
        switch (categoryName) {
          case "pipeline-setback-dtss":
            state.categoryBB.pipelineSetbackDtss = action.payload.data;
            break;

          case "pipeline-setback-trunk-sewers":
            state.categoryBB.pipelineSetbackTrunkSewers = action.payload.data;
            break;

          case "pipeline-setback-water-pipes":
            state.categoryBB.pipelineSetbackWaterPipes = action.payload.data;
            break;

          case "pipeline-dtss":
            state.categoryBB.pipelineDtss = action.payload.data;
            break;

          case "pipeline-trunk-sewers":
            state.categoryBB.pipelineTrunkSewers = action.payload.data;
            break;

          case "pipeline-water-pipes":
            state.categoryBB.pipelineWaterPipes = action.payload.data;
            break;

          default:
            console.log("Invalid selection->", categoryName);
        }
        if (action.payload.fromPage === "dashboard") {
          state.categoryBB.loading = APIStatus.loaded;
        }
      }
      state.isLoading = APIStatus.loaded;
    });
    builder.addCase(getCategoryLayerBB.rejected, (state, action) => {
      state.categoryBBError = action.payload;
      state.categoryBB.loading = APIStatus.failed;
      state.isLoading = APIStatus.failed;
    });
    builder.addCase(getBBLocations.fulfilled, (state, action) => {
      state.bbLocations = action.payload.data;
      state.isLoading = APIStatus.loaded;
    });
    builder.addCase(getBBLocations.rejected, (state, action) => {
      state.bbLocationsError = action.payload;
      state.isLoading = APIStatus.failed;
    });

    builder.addCase(getAllAssets.pending, (state, action) => {
      state.assetData = null;
      state.isLoading = APIStatus.loading;
    });
    builder.addCase(getAllAssets.fulfilled, (state, action) => {
      state.isLoading = APIStatus.loaded;
      state.assetDataDetails.count = action.payload.count;
      state.assetData = action.payload.results;
    });
    builder.addCase(getAllAssets.rejected, (state, action) => {
      state.isLoading = APIStatus.failed;
    });

    builder.addCase(getAssetAttachmentHistoryList.pending, (state, action) => {
      state.assetAttachmentHistoryList = null;
    });
    builder.addCase(
      getAssetAttachmentHistoryList.fulfilled,
      (state, action) => {
        state.assetAttachmentHistoryList = action.payload.data;
      }
    );
    builder.addCase(getAssetAttachmentHistoryList.rejected, (state, action) => {
      state.assetAttachmentHistoryList = null;
    });

    builder.addCase(addNewAsset.pending, (state, action) => {
      state.isLoading = APIStatus.loading;
      state.newAsset = null;
      state.newAssetError = null;
    });
    builder.addCase(addNewAsset.fulfilled, (state, action) => {
      state.isLoading = APIStatus.loaded;
      state.newAsset = action.payload;
      state.newAssetError = null;
    });
    builder.addCase(addNewAsset.rejected, (state, action) => {
      state.isLoading = APIStatus.failed;
      state.newAsset = null;
      state.newAssetError = action.error.message;
    });

    builder.addCase(editAsset.pending, (state, action) => {
      state.isLoading = APIStatus.loading;
      state.editedAsset = null;
      state.editedAssetError = null;
    });
    builder.addCase(editAsset.fulfilled, (state, action) => {
      state.isLoading = APIStatus.loaded;
      state.editedAsset = action.payload;
      state.editedAssetError = null;
    });
    builder.addCase(editAsset.rejected, (state, action) => {
      state.isLoading = APIStatus.failed;
      state.editedAsset = null;
      state.editedAssetError = action.error.message;
    });

    builder.addCase(attachAsset.pending, (state, action) => {
      state.isLoading = APIStatus.loading;
      state.attachedAsset = null;
      state.attachedAssetError = null;
    });
    builder.addCase(attachAsset.fulfilled, (state, action) => {
      state.isLoading = APIStatus.loaded;
      state.attachedAsset = action.payload;
      state.attachedAssetError = null;
    });
    builder.addCase(attachAsset.rejected, (state, action) => {
      state.isLoading = APIStatus.failed;
      state.attachedAssetError = action.error.message;
      state.attachedAsset = null;
    });

    builder.addCase(replaceAssetDevice.pending, (state, action) => {
      state.isLoading = APIStatus.loading;
      state.attachedAsset = null;
      state.attachedAssetError = null;
    });
    builder.addCase(replaceAssetDevice.fulfilled, (state, action) => {
      state.isLoading = APIStatus.loaded;
      state.attachedAsset = action.payload;
      state.attachedAssetError = null;
    });
    builder.addCase(replaceAssetDevice.rejected, (state, action) => {
      state.isLoading = APIStatus.failed;
      state.attachedAssetError = action.error.message;
      state.attachedAsset = null;
    });

    builder.addCase(detachAsset.pending, (state, action) => {
      state.isLoading = APIStatus.loading;
      state.detachedAsset = null;
      state.detachedAssetError = null;
    });
    builder.addCase(detachAsset.fulfilled, (state, action) => {
      state.isLoading = APIStatus.loaded;
      state.detachedAsset = action.payload;
      state.detachedAssetError = null;
    });
    builder.addCase(detachAsset.rejected, (state, action) => {
      state.isLoading = APIStatus.failed;
      state.detachedAsset = null;
      state.detachedAssetError = action.error.message;
    });

    builder.addCase(getAssetsM.pending, (state, action) => {
      state.getAssetLoading = APIStatus.loading;
    });
    builder.addCase(getAssetsM.fulfilled, (state, action) => {
      state.assets = action.payload ? action.payload.data.results : null;
      state.getAssetLoading = APIStatus.loaded;
    });
    builder.addCase(getAssetsM.rejected, (state, action) => {
      state.getAssetLoading = APIStatus.failed;
    });

    builder.addCase(getAssetAlarms.pending, (state, action) => {
      state.isLoading = APIStatus.loading;
      state.assetAlarms = null;
      state.assetAlarmsError = null;
    });
    builder.addCase(getAssetAlarms.fulfilled, (state, action) => {
      state.isLoading = APIStatus.loaded;
      console.log(action.payload?.data?.results);
      state.assetAlarms = action.payload?.data?.results;
      state.assetAlarmsError = null;
    });
    builder.addCase(getAssetAlarms.rejected, (state, action) => {
      state.isLoading = APIStatus.failed;
      state.assetAlarms = null;
      state.assetAlarmsError = action.error.message;
    });
  },
});

export const { resetAssetTypeBB } = assetSlice.actions;

export default assetSlice.reducer;
